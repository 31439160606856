<template>
  <div
    ref="headingRef"
    class="flex items-center gap-6 text-base !capitalize before:inline-block before:h-px before:w-0 before:bg-neutral-800 before:transition-all before:duration-500 before:content-[''] lg:text-xl"
    :class="isMarkerVisible && 'before:w-10'"
  >
    <span ref="textRef">{{ content }}</span>
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'
import { SplitText } from 'gsap/SplitText'

defineProps({
  content: {
    type: String,
    required: true
  }
})

const headingRef = ref<HTMLElement | null>(null)
const textRef = ref<HTMLElement | null>(null)
const split = ref<null | any>(null)
const tween = ref<null | any>(null)
const isMarkerVisible = ref(false)

const { stop } = useIntersectionObserver(
  headingRef,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting) {
      tween.value?.play()
    }
  },
  {
    threshold: 0.1
  }
)

onMounted(async() => {
  await nextTick(() => {
    if (textRef.value) {
      gsap.registerPlugin(SplitText)

      split.value = new SplitText(textRef.value, {
        type: 'lines, words',
        linesClass: 'split-child'
      })

      gsap.set(split.value.words, { perspective: 900 })

      const childSplit = new SplitText(textRef.value, {
        type: 'lines ,words',
        linesClass: 'split-child'
      })

      gsap.set(childSplit.words, { perspective: 900 })

      tween.value = gsap.from(childSplit.words, {
        yPercent: 350,
        duration: 1.2,
        ease: 'power4.out',
        stagger: {
          each: 0.06
        },
        onStart: () => {
          isMarkerVisible.value = true
        },
        paused: true
      })
    }
  })
})

onUnmounted(() => {
  tween?.value?.kill()
  stop()
})
</script>
